import React from 'react';
import YoutubeEmbed from './YoutubeEmbed';
import AOS from 'aos';
import BlockquotePort from './BlockquotePort';
AOS.init();



const whatido = () => {
    return(
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>Portfolio</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div>
                <YoutubeEmbed />
            </div>
            <BlockquotePort />
            <div className="spacer-double"></div>
            <div className="spacer-double"></div>
        </div>
    );
}

export default whatido;