import React, { useEffect } from 'react';

const YoutubeEmbed = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.elfsight.com/platform/platform.js';
    script.defer = true;
    script.setAttribute('data-use-service-core', '');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div className="elfsight-app-ecc52b20-77ec-41cd-ab7a-5db426e39468" data-elfsight-app-lazy></div>
  );
};

export default YoutubeEmbed;
