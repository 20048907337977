import React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "../src/pages/home";
// import Homelight from "../src/pages/homeLight";
// import Homelightnew from "../src/pages/homeLightnew";

function App() {
  return (
  	<div>
	  <BrowserRouter>
	    <Routes>
		    <Route path = "/" element={< Home/>}/>
		    {/* <Route path = "/homelight" element={< Homelight/>}/>
		    <Route path = "/homelightnew" element={< Homelightnew/>}/> */}
		</Routes>
	  </BrowserRouter>
    </div>
  );
}

export default App;