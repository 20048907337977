import React from 'react';
import Typed from "react-typed";
import AOS from 'aos';
AOS.init();

const BlockquotePort = () => {
    return (
        <div className="section bg-top bg-bottom py-0">
            <div className="py-5 position-relative"
                data-aos="fade"
                data-aos-delay="100"
                data-aos-duration="1000"
                data-aos-easing="ease"
                data-aos-once="true"
            >
                <div className="container" id="blockport">
                    <div className="row align-items-center">
                        <div className="col-md-10 offset-md-1">
                            <div className="spacer-double"></div>

                            <div className="de_3d-box-2">
                                <div className="d-inner">
                                    <i className="fa fa-heart-o"></i>
                                    <div className="text">
                                        <h3>

                                        <Typed
                                            strings={["Unlock creativity with us! Subscribe on YouTube, follow us on Instagram and across socials for exclusive content and updates.", "Let's Make it BIG Together!"]}
                                            typeSpeed={25}
                                            backSpeed={25}
                                            loop
                                            />
                                            </h3>
                                        

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="spacer-double"></div>
                    <div className="spacer-double"></div>
                </div>
            </div>
        </div>
    );
}

export default BlockquotePort;