import React from 'react';
import "react-circular-progressbar/dist/styles.css";
import AOS from 'aos';
AOS.init();

const hero = () => {
	return (
		<div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                    data-aos="fade-up"
                    data-aos-delay="0"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <h2>About Us</h2>
                    <div className="space-border"></div>
					<p>Welcome to Hassan Ali Productions (HAP), where innovation meets artistry to create an unparalleled audio and video experience. At HAP, we believe in the power of storytelling through sound and visuals. Our journey began in Islamabad, Pakistan, with a vision to redefine the standards of audio and video production. What sets us apart is our commitment to excellence, fueled by cutting-edge technology and a passion for creativity.

	                </p>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="300"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
							<h3>Our Mission</h3>
							Elevate entertainment through creativity and innovation. We transcend the conventional studio, serving as a dedicated hub that inspires and sets new benchmarks.

                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4" 
                    data-aos="fade"
                    data-aos-delay="400"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_cogs id-color-2"></i>
                            <div className="text">
							<h3>Why Choose HAP?</h3>
							We take pride in setting new industry standards, providing top-notch quality at the best prices. Dedicated to redefining entertainment boundaries.Trusted by notable clients.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4"
                    data-aos="fade"
                    data-aos-delay="500"
                    data-aos-duration="1000"
                    data-aos-easing="ease"
                    data-aos-once="true"
                    >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_group id-color-2"></i>
                            <div className="text">
							<h3>Join Our Adventure</h3>
							Calling all content creators, musicians, and businesses ready to make waves in the digital realm! HAP invites you to explore our services, dive into our portfolio, and be part of a limitless entertainment journey.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
	);
}

export default hero;