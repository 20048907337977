import { Parallax } from "react-parallax";
import { Link } from "react-scroll";
import Navbar from '../layout/Navbar';
import Hero from '../section/hero';
import About from '../section/about';
import Blockquote from '../section/blockquote';
import Whatido from '../section/whatido';
import Contact from '../section/contact';
import Footer from '../section/footer';
import Preloader from "../layout/Preloader";
import ScrollToTopBtn from '../layout/ScrollToTop';
import { createGlobalStyle } from 'styled-components';

const image1 = "./img/background/4.jpg";

const GlobalStyles = createGlobalStyle`
  .navbar-brand .imginit{
      display: block ;
    }
    .navbar-brand .imgsaly{
      display: none !important;
    }
`;

function home() {
  return (
    <>
      <GlobalStyles />
      <Preloader />
      <div className="home">

        <header id="header-wrap">
          <Navbar />
        </header>

        {/* HERO */}
        <section id="hero-area" className="bg-bottom py-0">
          <Parallax bgImage={image1} strength={100} blur={1}>
            <Hero />
            <Link smooth spy to="about">
              <span className="mouse transition" id="fly">
                <span className="scroll"></span>
              </span>
            </Link>
          </Parallax>
        </section>

        {/* ABOUT */}
        <section id="about" className="pb-0">
          <About />
          <Blockquote />
        </section>

        {/* What I DO */}
        <section id="whatido">
          <Whatido />
        </section>

        {/* contact */}
        <section id="contact" className="pb-0">
          <Contact />
          <Footer />
        </section>

        <div className="float-text">
          <div className="de_social-icons">
          <a href="https://www.facebook.com/hap.studio" target="_blank" rel="noreferrer" style={{ color: '#cf1f1f' }}>
  <i className="fa fa-facebook"></i>
</a>
<a href="https://www.instagram.com/hap.studiogram" target="_blank" rel="noreferrer" style={{ color: '#cf1f1f' }}>
  <i className="fa fa-instagram"></i>
</a>
<a href="https://twitter.com/hap.studio" target="_blank"  rel="noreferrer" style={{ color: '#cf1f1f' }}>
  <i className="fa fa-twitter"></i>
</a>
<a href="https://youtube.com/@hap.studio"  target="_blank" rel="noreferrer" style={{ color: '#cf1f1f' }}>
  <i className="fa fa-youtube"></i>
</a>
<a href="https://www.tiktok.com/@hap.studio" target="_blank" rel="noreferrer" style={{ color: '#cf1f1f' }}>
  <i className="fa fa-tiktok"></i>
</a>

          </div>
          <span>Follow Us</span>
        </div>
      </div>
      <ScrollToTopBtn />
    </>
  );
}

export default home;
